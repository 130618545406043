import axios from 'axios'

export function url (url, params) {
  let urlObj

  try {
    urlObj = new URL(url)
  } catch (e) {
    urlObj = new URL(url, window.location.origin)
  }

  const queryParams = new URLSearchParams(urlObj.search)

  // Iterate over the params object and handle each key-value pair
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(item => {
        if (typeof item === 'object') {
          queryParams.append(`${key}[]`, JSON.stringify(item))
        } else {
          queryParams.append(`${key}[]`, item)
        }
      })
    } else if (typeof value === 'object') {
      queryParams.set(key, JSON.stringify(value))
    } else {
      queryParams.set(key, value)
    }
  })

  urlObj.search = queryParams.toString()

  return url.startsWith('/') ? urlObj.pathname + urlObj.search : urlObj.toString()
}

export function request (method, url, handler = function () {}, data = {}, options = {}) {
  return axios[method.toLowerCase()](url, data, options)
    .then(function (response) {
      return handler(response.data)
    })
    .catch(function (thrown) {
      if (thrown.code === 'ECONNABORTED') {
        return { data: null }
      } else {
        throw thrown
      }
    })
}

export function requestPromise (method, url, data = {}, options = {}) {
  return new Promise((resolve, reject) => {
    request(
      method,
      url,
      (data) => {
        resolve(data)
      },
      data,
      options
    ).catch(reject)
  })
}
