<template>
  <Card
    class="EntityCard"
    :color="color"
    as="a"
    :href="href"
  >
    <div
      v-if="!placeholder"
      class="content"
    >
      <i
        v-for="icon in icons"
        :key="icon"
        :class="'right floated ' + icon + ' icon'"
      />
      <div
        v-if="$slots.header"
        class="header"
      >
        <slot name="header" />
      </div>
      <div
        v-if="$slots.description"
        class="meta"
      >
        <slot name="description" />
      </div>
    </div>
    <div
      v-else
      class="content"
    >
      <div class="ui fluid placeholder">
        <div class="paragraph">
          <div class="line" />
          <div class="line" />
        </div>
      </div>
    </div>
  </Card>
</template>
<script>
import { Card } from 'vue-fomantic-ui'

export default {
  tag: 'entity-card',
  components: { Card },
  props: {
    color: {
      type: String,
      default: 'grey'
    },
    href: {
      type: String,
      default: ''
    },
    placeholder: {
      type: Boolean,
      default: false
    },
    icons: {
      type: Array,
      default: () => []
    }
  }
}
</script>
