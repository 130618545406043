<template>
  <div class="map-button-container">
    <button
      class="ui primary icon button"
      @click="zoomIn"
    >
      <i class="plus icon" />
    </button>
    <button
      class="ui primary icon button"
      @click="zoomOut"
    >
      <i class="minus icon" />
    </button>
  </div>
  <div
    ref="mapContainer"
    class="map"
  />
</template>

<script>
import 'ol/ol.css'
import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import { fromLonLat } from 'ol/proj'
import { Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource, XYZ } from 'ol/source'
import { Feature } from 'ol'
import Point from 'ol/geom/Point'
import { Style, Icon, Fill, Stroke, Circle as CircleStyle } from 'ol/style'
import { defaults } from 'ol/control'

export default {
  props: {
    mapSource: {
      type: String,
      required: true
    },
    latitude: {
      type: Number,
      required: true
    },
    longitude: {
      type: Number,
      required: true
    },
    icon: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      map: null
    }
  },
  mounted () {
    let image
    if (this.icon) {
      image = new Icon({
        src: require('@/images/' + this.icon),
        scale: 0.6
      })
    } else {
      image = new CircleStyle({
        radius: 10,
        fill: new Fill({ color: 'red' }),
        stroke: new Stroke({
          color: 'white',
          width: 2
        })
      })
    }

    if (typeof window === 'undefined' || typeof window.ResizeObserver === 'undefined') {
      // exit for the test as ResizeObserver is not available there
      return
    }

    this.map = new Map({
      target: this.$refs.mapContainer,
      layers: [
        new TileLayer({
          source: new XYZ({
            url: this.mapSource,
            attributions: [
              '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            ],
            attributionsCollapsible: false
          })
        }),
        new VectorLayer({
          source: new VectorSource({
            features: [
              new Feature({
                geometry: new Point(fromLonLat([this.longitude, this.latitude]))
              })
            ]
          }),
          style: new Style({ image })
        })
      ],
      view: new View({
        center: fromLonLat([this.longitude, this.latitude]),
        zoom: 16
      }),
      controls: defaults({ zoom: false, attribution: true })
    })
  },
  methods: {
    zoomIn () {
      const view = this.map.getView()
      view.setZoom(view.getZoom() + 1)
    },
    zoomOut () {
      const view = this.map.getView()
      view.setZoom(view.getZoom() - 1)
    }
  }
}
</script>
