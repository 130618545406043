<template>
  <p
    v-for="(paragraph, index) in paragraphs"
    :key="index"
  >
    {{ paragraph.length > 0 ? paragraph : '\xa0' }}
  </p>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  computed: {
    paragraphs () {
      return this.text.split('\n')
    }
  }
}
</script>
