<template>
  <Teleport to="body">
    <SuiDimmer
      v-if="isPrompting || (!optional && !isGranted)"
      active
      page
    >
      <SuiHeader
        v-if="isPrompting"
        as="h2"
        icon
        inverted
      >
        Zur Nutzung dieser Funktion bitte den Zugriff auf {{ objectNames }} erlauben
      </SuiHeader>
      <div v-else-if="isDenied">
        <SuiHeader
          as="h2"
          icon
          inverted
        >
          <SuiIcon
            name="frown outline"
          />
          Der Zugriff auf {{ objectNames }} wurde verwert. Die Funktion kann derzeit nicht verwendet werden.
        </SuiHeader>
        <SuiButton @click="() => reload()">
          Erneut versuchen
        </SuiButton>
      </div>
      <div v-else-if="isError">
        <SuiHeader
          as="h2"
          icon
          inverted
        >
          <SuiIcon
            name="frown outline"
          />
          Bei dem Zugriff auf {{ objectNames }} ist ein Fehler aufgetreten.
        </SuiHeader>
        <SuiButton @click="() => reload()">
          Erneut versuchen
        </SuiButton>
      </div>
      <SuiLoader v-else />
    </SuiDimmer>
  </Teleport>
</template>
<script setup>
import {
  SuiIcon,
  SuiDimmer,
  SuiHeader,
  SuiButton,
  SuiLoader
} from 'vue-fomantic-ui'
import { onMounted, computed, ref } from 'vue'

const emit = defineEmits(['change', 'granted', 'denied', 'error', 'requested'])
const currentPermissions = ref({})
const isPrompting = ref(false)
const isGranted = ref(false)
const isDenied = ref(false)
const isError = ref(false)

const props = defineProps({
  permissions: {
    type: Array,
    required: true
  },
  optional: {
    type: Boolean,
    required: false,
    default: false
  },
  request: {
    type: Boolean,
    required: false,
    default: true
  }
})

function capitalizeFirstLetter (val) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1)
}

const reload = () => {
  window.location.reload()
}

const objectNames = computed(() => {
  const names = []

  props.permissions.forEach((type) => {
    switch (type) {
      case 'camera':
        type = 'Kamera'
        break
      case 'microphone':
        type = 'Mikrofon'
        break
      default:
        type = capitalizeFirstLetter(type)
        break
    }

    names.push(type)
  })

  return names.join(', ').replace(/,\s+([^,\s]*)$/, ' und $1')
})

const getPermissions = () => {
  return JSON.parse(JSON.stringify(currentPermissions.value))
}

const changed = () => {
  const data = getPermissions()
  let prompting = Object.values(data).length > 0
  let granted = Object.values(data).length > 0
  let denied = false
  let error = false

  Object.values(data).forEach((value) => {
    error = error || value === 'error'
    denied = denied || value === 'denied'
    prompting = prompting && value === 'prompt'
    granted = granted && value === 'granted'
  })

  isGranted.value = granted
  isError.value = error
  isPrompting.value = prompting
  isDenied.value = denied

  emit('change', data)
  if (granted) {
    emit('granted', data)
  } else if (denied) {
    emit('denied', data)
  } else if (error) {
    emit('error', data)
  }
}

const setState = (key, value) => {
  if (currentPermissions.value[key] !== value) {
    currentPermissions.value[key] = value
    changed()
  }
}

const handlers = {}

handlers.microphone = handlers.camera = () => {
  return new Promise((resolve, reject) => {
    navigator.mediaDevices.getUserMedia({
      audio: props.permissions.indexOf('microphone') !== -1,
      video: props.permissions.indexOf('camera') !== -1
    }).then(() => {
      resolve('granted')
    }).catch((e) => {
      let name = e
      if (e instanceof DOMException) {
        name = e.name
      }

      if (name.indexOf('NotAllowedError') !== -1) {
        resolve('denied')
      } else {
        reject(name)
      }
    })
  })
}

const requestPermission = (type) => {
  if (typeof handlers[type] !== 'undefined') {
    handlers[type]().then((state) => {
      setState(type, state)
    }).catch(() => {
      setState(type, 'error')
    })
  }
}

const registerCheck = (type) => {
  setState(type, 'unknown')
  return new Promise((resolve) => {
    navigator.permissions.query({
      name: type
    }).then((status) => {
      status.onchange = () => {
        setState(type, status.state)
      }
      setState(type, status.state)

      if (props.request && status.state !== 'granted') {
        requestPermission(type)
      }

      resolve()
    }).catch((e) => {
      setState(type, 'error')
      resolve()
    })
  })
}

onMounted(() => {
  const requests = []

  props.permissions.forEach((type) => {
    requests.push(registerCheck(type))
  })

  Promise.allSettled(requests).then(() => {
    emit('requested', getPermissions())
  }).catch(function () {
  })
})
</script>
