<template>
  <div
    v-if="error !== null"
    class="ui error message"
  >
    <div class="header">
      Fehler
    </div>
    <p>{{ error.message }}</p>
  </div>
  <EntityCardList
    v-else
    small
    :placeholder="loading ? placeholder : 0"
    :items="items"
  />
</template>
<script>
import EntityCardList from '@/components/_partials/Organism/EntityCardList.ce.vue'
import { request } from '@/js/request.js'

export default {
  tag: 'unit-list',
  components: { EntityCardList },
  props: {
    placeholder: {
      type: Number,
      default: 1
    },
    building: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    error: null,
    loading: false,
    items: []
  }),
  mounted () {
    this.$el.addEventListener('display', () => {
      this.load()
    }, false)
  },
  methods: {
    async load () {
      this.error = null
      this.loading = true
      return request('get', '/api/v1/unit/?unit[building]=' + this.building, (data) => {
        const items = []
        for (const item of data) {
          items.push({
            href: '/unit/detail/' + item.id,
            color: item.verified ? 'teal' : 'grey',
            link: {
              label: 'WE',
              reference: item.home_id,
              icon: { background: item.verified ? 'teal' : 'grey', name: item.verified ? 'check' : 'question' },
              info: item.location_in_building ? item.location_in_building : '-'
            }
          })
        }
        this.items = items
        this.loading = false
      }).catch((e) => {
        this.error = e
        this.loading = false
      })
    }
  }
}
</script>
