<template>
  <div class="unit-selection">
    <sui-dropdown
      v-model="selectedUnit"
      :options="options"
      :disabled="options.length <= 1"
      selection
    />
  </div>
</template>

<script setup>
import { request } from '@/js/request'
import { SuiDropdown } from 'vue-fomantic-ui'
import * as R from 'ramda'
import { ref, watch } from 'vue'

const props = defineProps({
  buildingId: {
    type: Number,
    required: true
  },
  unitId: {
    type: Number,
    required: true
  }
})

const options = ref([])
const selectedUnit = ref(null)

request('get', `/api/v1/building/${props.buildingId}`,
  response => {
    const defaultToDash = R.defaultTo('–')
    const commonClasses = 'small circular'

    response.units.forEach(function (unit) {
      const option = {
        text: `${defaultToDash(unit.location_in_building)} (${unit.home_id})`,
        icon: unit.verified
          ? `${commonClasses} check c-background--teal`
          : `${commonClasses} question c-background--grey`,
        value: unit.id
      }
      options.value.push(option)
      if (unit.id === props.unitId) {
        selectedUnit.value = option
      }
    })
  }
)

watch(selectedUnit, function (value) {
  if (value.value !== props.unitId) {
    window.location.assign(`/unit/detail/${value.value}`)
  }
})
</script>

<style scoped>
.unit-selection {
  min-width: 40%;
  float: right;
}
</style>
