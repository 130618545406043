<template>
  <SuiModal v-model="show">
    <SuiModalHeader icon>
      <SuiIcon :name="icon" />
      {{ title }}
    </SuiModalHeader>
    <SuiModalContent>{{ currentQuestion }}</SuiModalContent>
    <SuiModalActions>
      <SuiButton
        primary
        icon="remove"
        :content="abort"
        @click="() => onAbort()"
      />
      <SuiButton
        color="red"
        icon="checkmark"
        :content="confirm"
        @click="() => onConfirm()"
      />
    </SuiModalActions>
  </SuiModal>
</template>
<script setup>
import { ref, watch } from 'vue'
import {
  SuiIcon,
  SuiModal,
  SuiModalActions,
  SuiButton,
  SuiModalContent,
  SuiModalHeader
} from 'vue-fomantic-ui'

const props = defineProps({
  icon: {
    type: String,
    required: false,
    default: 'exclamation'
  },
  title: {
    type: String,
    required: false,
    default: 'Bestätigen'
  },
  question: {
    type: String,
    required: true,
    default: 'Die Aktion wirklich ausführen?'
  },
  abort: {
    type: String,
    required: true,
    default: 'Abbrechen'
  },
  confirm: {
    type: String,
    required: true,
    default: 'Ja'
  }
})

const show = ref(false)
const confirmed = ref(false)
const currentQuestion = ref(props.question)

let currentPromise
let currentPromiseResolve
let currentPromiseReject

watch(show, (newShow, oldShow) => {
  if (newShow === false) {
    if (confirmed.value) {
      confirmed.value = false
      currentPromiseResolve()
    } else {
      currentPromiseReject()
    }
  }
})

function onAbort () {
  show.value = false
}

function onConfirm () {
  confirmed.value = true
  show.value = false
}

const ask = (question) => {
  if (typeof question !== 'undefined') {
    currentQuestion.value = question
  } else {
    currentQuestion.value = props.question
  }

  if (show.value) {
    return currentPromise
  }

  confirmed.value = false
  show.value = true

  currentPromise = new Promise((resolve, reject) => {
    currentPromiseResolve = resolve
    currentPromiseReject = reject
  })

  return currentPromise
}

defineExpose({
  ask
})
</script>
