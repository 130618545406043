import { defineStore } from 'pinia'
import { request } from '@/js/request'

export const useBuildingStore = defineStore('BuildingStore', {
  state: () => {
    return {
      building: {
        units: []
      }
    }
  },
  getters: {
    buildingData: (state) => state.building,
    units: (state) => state.building.units,
    getBuildingId: (state) => state.building.id
  },
  actions: {
    async fill (buildingId) {
      await request('get', '/api/v1/building/' + buildingId,
        response => {
          this.building = response
        }
      )
    },
    async addUnit (buildingId) {
      await request('post', '/api/v1/unit/add/' + buildingId,
        response => {
          this.buildingData.units.push(response)
        }
      )
    }
  }
})
