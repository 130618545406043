import $ from 'jquery'
import { createApp } from 'vue'
import PreserviceConcept from '@/components/Project/ThePreserviceConcept.vue'
import ItemForm from '@/components/Project/TheItemForm.vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import FlashMessage from '@/components/_partials/FlashMessage.vue'
import SearchApp from '@/components/Project/SearchApp'
import {
  parseMoney,
  parseInt0,
  formatMoney,
  multiplyFields
} from '@/js/field-calculator'
import * as calculate from '@/js/calculation'
import TopicRights from '@/components/History/TopicRights'
import ShowPrivileges from '@/components/History/ShowPrivileges'
import TopicTreeSelect from '@/components/History/TopicTreeSelect'
import { isDateValid } from '@/js/date-validator'
import ProjectUploader from '@/components/Document/ProjectUploader'
import TheProjectFilter from '@/components/Project/TheProjectFilter'
import UserPrivilegeAccessRights from '@/components/AccessRight/UserPrivilegeAccessRights'
import { createVue } from '@/js/vue'
import { request } from '@/js/request'

/**
 * Opens a window.
 *
 * @param {string} name - Target address
 * @param {string} titel - Window title
 * @param {number} breite - Window width
 * @param {number} hoehe - Window height
 *
 * @returns {Window}
 */
export function fenster (name, titel = '', breite = 1040, hoehe = 1040) {
  const links = screen.width / 2 - breite / 2
  const oben = screen.height / 2 - hoehe / 2
  const newWin = window.open(name, titel,
    'width=' + breite +
    ',height=' + hoehe +
    ',top=' + oben +
    ',left=' + links +
    ',scrollbars=yes,toolbar=0,location=0'
  )

  if (newWin && !newWin.closed) {
    newWin.focus()
  }

  return newWin
}

export function registerNewWindow () {
  const width = this.dataset.width !== undefined ? this.dataset.width : 1040
  const height = this.dataset.height !== undefined ? this.dataset.height : 1040
  const href = this.dataset.href !== undefined ? this.dataset.href : this.href

  fenster.call(this, href, this.dataset.newWindow, width, height)

  return false
}

export function initCountdownWindow () {
  const backButton = $('#countdownBackButton')
  backButton.on('click', function () {
    handleCloseWindow(backButton)
  })
  if (backButton.length) {
    let counter = 5
    const interval = setInterval(function () {
      --counter
      if (backButton.data('type') === 'close') {
        backButton.text('Fenster schließen (' + counter + ')')
      } else if (backButton.data('type') === 'forward') {
        backButton.text('Weiterleitung (' + counter + ')')
      }
      if (counter === 0) {
        clearInterval(interval)
        handleCloseWindow(backButton)
      }
    }, 1000)
  }
}

export function closeWindowAndUpdateOpener (link = null) {
  updateOpener(link)
  closeWindow()
}

export function updateOpener (link = null) {
  if (window.opener && window.opener.location) {
    if (link) {
      window.opener.location.href = link
    } else {
      window.opener.location.reload()
    }
  }
}

function handleCloseWindow (button) {
  if (button.data('updateOpener') === 1) {
    closeWindowAndUpdateOpener(button.data('link'))
  } else {
    closeWindow()
  }
}

function initConceptFormWidget () {
  const itemForm = document.querySelector('form[name=item]')
  const isDisabled = itemForm !== null && itemForm.dataset.disabled !== undefined

  createVue(ItemForm, 'vue-concept-form', {
    disabled: isDisabled
  }, {
    initContents: 'contents',
    initFieldGroups: 'fieldGroups'
  })
  createVue(PreserviceConcept, 'vue-preservice', {
    disabled: isDisabled
  }, {
    initContents: 'contents'
  })
}

function initFlashMessages () {
  createVue(FlashMessage, 'message-component', {}, {
    color: 'messageColor',
    message: 'messageContent'
  })
}

export function initShowPrivilegesApp () {
  createVue(ShowPrivileges, 'show-privileges', {}, {
    userId: 'userId'
  })
}

export function initUserPrivilegeAccessRightsApp () {
  createVue(UserPrivilegeAccessRights, 'user-privilege-access-rights')
}

function filterProducts (projectType, product) {
  if (projectType.value === '') {
    for (let i = 1; i < product.childElementCount; ++i) {
      product.options[i].style.display = 'none'
    }
    return
  }

  filterProducts.projectTypes = request(
    'get',
    '/auftrag/types/' + projectType.value + '.json',
    function (products) {
      for (let i = 1; i < product.childElementCount; ++i) {
        const optionValue = parseInt(product.options[i].value)
        if (products.map(function (p) { return p.id }).indexOf(optionValue) === -1) {
          product.options[i].style.display = 'none'
        } else {
          product.options[i].style.removeProperty('display')
          if (products.find(p => p.id === optionValue).disabled) {
            product.options[i].disabled = true
          }
        }
      }
    })
}

function switchTasks (e) {
  const target = $(e.currentTarget)
  const type = target.attr('id').substring(7)

  target.parent().find('.active').removeClass('active').addClass('no-active')
  target.removeClass('no-active').addClass('active')

  const displayAssignments = $('.user-displayAssignments').text()

  const parent = target.parents('.display-switch-target')
  const aListGroup = parent.find('.aListGroup')

  aListGroup.hide()
  if (displayAssignments === 'detail') {
    aListGroup.filter('.' + type).show()
  }

  const item = target.parents('.display-switch-target')
    .find('.historie-item')

  item.hide()
  item.filter('.' + type).show()

  $('#toggle-comments').removeClass('active').addClass('no-active')

  e.preventDefault()
}

function switchAll (e) {
  $(this).parent().find('.active').removeClass('active').addClass('no-active')
  $(this).removeClass('no-active').addClass('active')
  const parent = $(this).parents('.display-switch-target')
  parent.find('.historie-item').show()

  const displayAssignments = parent.find('.user-displayAssignments').text()
  const aListGroup = parent.find('.aListGroup')
  aListGroup.hide()
  if (displayAssignments === 'detail') {
    aListGroup.show()
  }

  $('#toggle-comments').removeClass('active').addClass('no-active')
  e.preventDefault()
}

function Summator (target) {
  this.summands = []
  if (target instanceof HTMLInputElement) {
    this.target = target
  } else {
    this.target = null
  }
}

export function initSearchApp () {
  let router = null
  let searchAppMountPoint = null
  let initCallback
  let confirmText
  let searchUrl = null

  if (document.getElementById('building-search-app')) {
    searchAppMountPoint = document.getElementById('building-search-app')
    confirmText = searchAppMountPoint.dataset.confirmText
    initCallback = searchAppMountPoint.dataset.initCallback
    searchUrl = searchAppMountPoint.dataset.searchUrl
    const ShowBuilding = () => import('@/components/Project/ShowBuilding')
    const BuildingSearchPage = () => import('@/components/Project/BuildingSearchPage')

    router = createRouter({
      history: createWebHashHistory(),
      routes: [
        {
          path: '/',
          name: 'buildingSearchList',
          props: {
            initSearchUrl: searchUrl || undefined
          },
          component: BuildingSearchPage
        },
        {
          path: '/show/:buildingID?',
          name: 'showBuilding',
          component: ShowBuilding
        }
      ]
    })
  } else if (document.getElementById('persons-search-app')) {
    confirmText = document.getElementById('persons-search-app').dataset.confirmText
    initCallback = document.getElementById('persons-search-app').dataset.initCallback
    const ShowPerson = () => import('@/components/Project/ShowPerson')
    const PersonSearchPage = () => import('@/components/Project/PersonSearchPage')

    searchAppMountPoint = document.getElementById('persons-search-app')
    router = createRouter({
      history: createWebHashHistory(),
      routes: [
        {
          path: '/',
          name: 'showSearch',
          component: PersonSearchPage
        },
        {
          path: '/show/:personID?',
          name: 'showPerson',
          component: ShowPerson
        }
      ]
    })
  }
  if (searchAppMountPoint !== null) {
    const searchApp = createApp(SearchApp, {
      initConfirmText: confirmText,
      initCallback
    })
    searchApp.use(router)
    searchApp.mount(searchAppMountPoint)
  }
}

export function initTopicRights () {
  return createVue(TopicRights, 'topic-rights-app', {}, {
    initClientType: 'clientType',
    initClientId: 'clientId',
    contentDisabled: 'contentDisabled'
  })
}

export function initProjectFilter () {
  return createVue(TheProjectFilter, 'project-filter', {}, {
    phases: 'phases'
  })
}

export function initTopicTreeSelect () {
  return createVue(TopicTreeSelect, 'topic-tree-select', {}, {
    initSelectedTopics: 'selectedTopics'
  })
}

Summator.prototype.summarize = function () {
  if (this.target === null) {
    return
  }
  let sum = 0

  let i
  for (i = 0; i < this.summands.length; ++i) {
    if (this.summands[i] instanceof HTMLSelectElement &&
      this.summands[i].selectedIndex !== -1) {
      const singlePrice = this.summands[i]
        .options[this.summands[i].selectedIndex]
        .dataset
        .price

      if (singlePrice !== undefined) {
        sum += parseMoney(singlePrice)
      }
    } else {
      sum += parseMoney(this.summands[i].value)
    }
  }
  this.target.value = formatMoney(sum)
  this.target.dispatchEvent(new Event('input'))
}

function filterBandwidths (selectEl, optGroupLabel) {
  let found = false
  for (let i = selectEl.childElementCount - 1; i > 0; --i) {
    const child = selectEl.children[i]
    if (child instanceof HTMLOptionElement) {
      selectEl.removeChild(child)
    } else if (child instanceof HTMLOptGroupElement && child.label === optGroupLabel) {
      for (let j = 0; j < child.children.length; ++j) {
        selectEl.insertBefore(child.children[j].cloneNode(true), null)
      }
      found = true
    }
  }
  return found
}

function unitTimesPrice (fields) {
  for (let i = 0; i < fields.length; ++i) {
    const inputs = fields[i].querySelectorAll('input')
    if (inputs.length < 3) {
      continue
    }
    const first = inputs[0]
    const middle = inputs[inputs.length - 2]
    const last = inputs[inputs.length - 1]

    const multiply = function (first, middle, last) {
      last.value = multiplyFields(first.value, middle.value)
      last.dispatchEvent(new Event('input'))
    }

    if (first instanceof HTMLInputElement &&
        middle instanceof HTMLInputElement &&
        last instanceof HTMLInputElement) {
      multiply(first, middle, last)
      first.addEventListener('input', multiply)
      middle.addEventListener('input', multiply)
    }
  }
}

function filterCalculationFields (product, bandwidth) {
  const tvRevenue = document.getElementById('tv-revenue')

  if (tvRevenue !== null) {
    tvRevenue.style.display = 'none'
  }
  const internetRevenue = document.getElementById('internet-revenue')
  if (internetRevenue !== null) {
    internetRevenue.style.display = 'none'
  }
  const privateCustomerSalesRevenue =
    document.getElementById('private-customer-sales-revenue')
  if (privateCustomerSalesRevenue !== null) {
    privateCustomerSalesRevenue.style.display = 'none'
  }
  const licenseFees = document.getElementById('license-fees')
  if (licenseFees !== null) {
    licenseFees.style.display = 'none'
  }

  if (product.value === '21' ||
      product.value === '22' ||
      product.value === '23') {
    tvRevenue !== null && tvRevenue.style.removeProperty('display')
    licenseFees !== null && licenseFees.style.removeProperty('display')
  }
  if (product.value === '22' ||
      product.value === '23' ||
      product.value === '24' ||
      product.value === '26') {
    bandwidth.style.removeProperty('display')
  }
  if (product.value === '22' ||
      product.value === '23' ||
      product.value === '24' ||
      product.value === '25' ||
      product.value === '26') {
    internetRevenue !== null && internetRevenue.style.removeProperty('display')
  }
  if (product.value === '21') {
    privateCustomerSalesRevenue !== null &&
      privateCustomerSalesRevenue.style.removeProperty('display')
  }
}

function sumTotalPrice (fields) {
  const sumTotalPriceGroups = {}

  let i
  for (i = 0; i < fields.length; ++i) {
    if (fields[i].dataset.sumTotalPrice in sumTotalPriceGroups === false) {
      const target = document.getElementById(fields[i].dataset.sumTotalPrice)
      sumTotalPriceGroups[fields[i].dataset.sumTotalPrice] = new Summator(target)
    }
    sumTotalPriceGroups[fields[i].dataset.sumTotalPrice].summands.push(fields[i])
  }

  for (const group in sumTotalPriceGroups) {
    const sumTotalPriceGroup = sumTotalPriceGroups[group]
    sumTotalPriceGroup.summarize()
    sumTotalPriceGroup.summands.forEach(function (summand) {
      summand.addEventListener('input', function () {
        sumTotalPriceGroup.summarize()
      })
    })
  }
}

function setOneTimeCost (conceptType, value) {
  const costs = document.getElementById(
    'calculation_oneTime' + conceptType + 'Cost'
  )
  if (costs === null) {
    return null
  }

  costs.value = value
  costs.dispatchEvent(new Event('input'))

  return costs
}

export function isEmpty (value) {
  return (typeof value === 'string' && !value.trim()) || typeof value === 'undefined' || value === null
}

export function queryConceptCalculation (bandwidth, product, concept) {
  const projectId = $('[name="calculation[project]"]').val()

  if (concept === '' || isNaN(projectId)) {
    return
  }
  request('get', '/api/v1/calculation/concept/' + projectId + '/' + concept, function (response) {
    const costs = {}
    Object.keys(response.costs).forEach(function (k) {
      costs[k] = formatMoney(response.costs[k] / 100)
    })

    setOneTimeCost('Infrastructure', costs.infrastruktur)
    setOneTimeCost('R&T', costs.rt)
    setOneTimeCost('Inhouse', costs.inhouse)
    setOneTimeCost('Provider', costs.vordienstleister)

    if (product !== null) {
      product.value = response.product ? response.product.toString() : ''
      product.dispatchEvent(new Event('change'))
    }

    if (bandwidth !== null && bandwidth.value === '') {
      bandwidth.value = response.bandwidth ? response.bandwidth.toString() : ''
      bandwidth.dispatchEvent(new Event('change'))
    }

    const slaPremium = $('#sla-premium')

    if (response.isRedundant) {
      slaPremium.show()
    } else if (slaPremium.length > 0) {
      slaPremium.find('input').val('')[0].dispatchEvent(new Event('input'))
      slaPremium.hide()
    }
  })
}

function calculateFromConcept (bandwidth, product) {
  const interestRate = document.getElementById('calculation_interestPerYear')
  const interest = document.getElementById('calculation-interest')
  const term = document.getElementById('calculation_term')
  const monthlyRevenueSum = document.getElementById('calculation-monthly-revenue-sum')
  const revenueSum = document.getElementById('calculation-one-time-revenue-sum')
  const costsSum = document.getElementById('calculation-one-time-costs-sum')
  const monthlyProviderCost = document.getElementById('calculation_monthlyProviderCost')

  const conceptToCalculate = $('[name="calculation[concept]"]')
  queryConceptCalculation(bandwidth, product, conceptToCalculate.val())
  conceptToCalculate.on('change', function (e) {
    queryConceptCalculation(bandwidth, product, e.target.value)
  })

  function calculateInterest () {
    const money = calculate.interest(costsSum.value, revenueSum.value, interestRate.value)

    interest.value = formatMoney(money)
    interest.dispatchEvent(new Event('input'))
  }

  if (interest !== null) {
    revenueSum.addEventListener('input', calculateInterest)
    costsSum.addEventListener('input', calculateInterest)
  }

  const maintenanceCosts = document.getElementById('calculation-maintenance-costs')
  function calculateMaintenanceCosts () {
    maintenanceCosts.value = formatMoney(
      calculate.maintenanceCosts(monthlyRevenueSum.value)
    )
    maintenanceCosts.dispatchEvent(new Event('input'))
  }

  if (maintenanceCosts !== null) {
    monthlyRevenueSum.addEventListener('input', calculateMaintenanceCosts)
  }

  const monthlyCostsSum = document.getElementById('calculation-monthly-costs-sum')
  function calculateMonthlyCostsSum () {
    monthlyCostsSum.value = formatMoney(calculate.monthlyCostsSum(
      interest.value, maintenanceCosts.value, monthlyProviderCost.value
    ))
    monthlyCostsSum.dispatchEvent(new Event('input'))
  }
  if (monthlyCostsSum !== null) {
    interest.addEventListener('input', calculateMonthlyCostsSum)
    maintenanceCosts.addEventListener('input', calculateMonthlyCostsSum)
    monthlyProviderCost.addEventListener('input', calculateMonthlyCostsSum)
  }

  const totalSales = document.getElementById('calculation-total-sales')
  function calculateTotalSales () {
    totalSales.value = formatMoney(
      parseMoney(monthlyRevenueSum.value) *
        parseInt0(term.value) +
        parseMoney(revenueSum.value)
    )
    totalSales.dispatchEvent(new Event('input'))
  }

  if (totalSales !== null) {
    monthlyRevenueSum.addEventListener('input', calculateTotalSales)
    term.addEventListener('input', calculateTotalSales)
    revenueSum.addEventListener('input', calculateTotalSales)
  }

  const grossProfit = document.getElementById('calculation-gross-profit')
  function calculateGrossProfit () {
    grossProfit.value = formatMoney(calculate.grossProfit(
      monthlyRevenueSum.value, monthlyCostsSum.value, term.value, costsSum.value, revenueSum.value
    ))
    grossProfit.dispatchEvent(new Event('input'))
  }

  if (grossProfit !== null) {
    monthlyRevenueSum.addEventListener('input', calculateGrossProfit)
    term.addEventListener('input', calculateGrossProfit)
    revenueSum.addEventListener('input', calculateGrossProfit)
    costsSum.addEventListener('input', calculateGrossProfit)
  }

  const capitalInvestment = document.getElementById('calculation-capital-investment')
  function calculateCapitalInvestment () {
    const months = parseInt0(term.value)

    capitalInvestment.value = formatMoney(
      parseMoney(costsSum.value) -
        parseMoney(revenueSum.value) +
        (parseMoney(interest.value) + parseMoney(maintenanceCosts.value)) *
        Math.ceil(months / 12) +
        parseMoney(monthlyProviderCost.value) * months
    )
    capitalInvestment.dispatchEvent(new Event('input'))
  }

  if (capitalInvestment !== null) {
    monthlyCostsSum.addEventListener('input', calculateCapitalInvestment)
    term.addEventListener('input', calculateCapitalInvestment)
  }

  const amortizationAfter = document.getElementById('calculation-amortization-after')
  function calculateAmortizationAfter () {
    amortizationAfter.value = calculate.amortizationAfter(
      capitalInvestment.value,
      monthlyRevenueSum.value
    )
    amortizationAfter.dispatchEvent(new Event('input'))
  }

  if (amortizationAfter !== null) {
    costsSum.addEventListener('input', calculateAmortizationAfter)
    monthlyCostsSum.addEventListener('input', calculateAmortizationAfter)
    term.addEventListener('input', calculateAmortizationAfter)
    monthlyRevenueSum.addEventListener('input', calculateAmortizationAfter)
  }

  const licenseFees = document.getElementById('calculation-license-fees')
  if (licenseFees !== null) {
    const tvRevenueSum = document.getElementById('tv-revenue-sum')

    tvRevenueSum.addEventListener('input', function () {
      licenseFees.value = formatMoney(parseMoney(tvRevenueSum.value) / 4.2)
      licenseFees.dispatchEvent(new Event('input'))
    })
  }

  const fixedIPAddress = document.getElementById('calculation_fixedIpAddress')
  const fixedIPAddressPrice = document.getElementById('fixed-ip-address-price')
  function showFixedIPAddress () {
    if (fixedIPAddress.selectedIndex === 4) {
      fixedIPAddressPrice.style.display = 'flex'
    } else {
      fixedIPAddressPrice.style.display = 'none'
      const fixedIPAddressInput = fixedIPAddressPrice.querySelector('input')
      fixedIPAddressInput.value = ''
      fixedIPAddressInput.dispatchEvent(new Event('input'))
    }
  }
  if (fixedIPAddress !== null && fixedIPAddressPrice !== null) {
    showFixedIPAddress()
    fixedIPAddress.addEventListener('input', showFixedIPAddress)
  }
}

/**
 * Product dependent fields
 */
export function bindProjectEventListeners () {
  const product = document.querySelector('[data-select="product"]')

  // Bandwidth
  const bandwidths = document.getElementsByClassName('bandwidth') // select
  const bandwidth = bandwidths.length > 0 ? bandwidths[0] : null
  const bandwidthWrap = document.getElementById('bandwidth') // div wrapper

  if (product instanceof HTMLSelectElement) {
    if (product.selectedIndex !== -1 && bandwidth instanceof HTMLSelectElement) {
      if (filterBandwidths(bandwidth, product.options[product.selectedIndex].label)) {
        bandwidthWrap.style.display = 'flex'
      } else {
        bandwidthWrap.style.display = 'none'
      }
    }
    product.addEventListener('change', function (event) {
      if (bandwidth instanceof HTMLSelectElement && this.options[this.selectedIndex]) {
        bandwidth.selectedIndex = 0
        if (filterBandwidths(bandwidth, this.options[this.selectedIndex].label)) {
          bandwidthWrap.style.display = 'flex'
        } else {
          bandwidthWrap.style.display = 'none'
        }
      }
    })

    // Hide/display calculation fields
    if (bandwidthWrap instanceof HTMLElement) {
      filterCalculationFields(product, bandwidthWrap)
      product.addEventListener('change', function (e) {
        filterCalculationFields(product, bandwidthWrap)
      })
    }
  }
  calculateFromConcept(bandwidth, product)

  /**
   * Project dependent selection
   */
  const projectType = document.querySelector('[data-select="project-type"]')
  if (product instanceof HTMLSelectElement && projectType instanceof HTMLSelectElement) {
    filterProducts(projectType, product)

    projectType.addEventListener('change', function (event) {
      if (bandwidth instanceof HTMLSelectElement &&
          bandwidthWrap &&
          product instanceof HTMLSelectElement) {
        bandwidth.selectedIndex = 0
        bandwidthWrap.style.display = 'none'
        product.selectedIndex = 0

        filterProducts(projectType, product)
      }
    })
  }
  unitTimesPrice(document.getElementsByClassName('per-unit-price-total'))
  sumTotalPrice(document.querySelectorAll('[data-sum-total-price]'))

  /**
   * Main project form
   */
  function onChangeProjectType (value) {
    const agreement = $('#project_agreement_project')
    const invoiceRecipientId = $('#project_invoice_recipient')
    const reference = $('#project_reference')

    if (value === '2') {
      $('#agreement .delete').trigger('click')
      agreement.closest('tr').hide()

      invoiceRecipientId.val(null).closest('tr').hide()
      $('#invoice-recipient').html('')

      reference.val('').closest('tr').hide()
    } else {
      agreement.closest('tr').show()
      invoiceRecipientId.closest('tr').show()
      reference.closest('tr').show()
    }
  }
  const projectTypes = $('select#projekttyp')
  if (projectTypes.length > 0) {
    const optionIndex = projectTypes[0].selectedIndex

    onChangeProjectType(projectTypes[0].options[optionIndex].value)
  }

  projectTypes.on('change', function () {
    if (this.value === '6') {
      $('#vertriebsmitarbeiter').show()
    } else {
      $('#vertriebsmitarbeiter').hide()
    }
    onChangeProjectType(this.value)
  })
}

export function closeWindow () {
  window.close()
}

export function initCloseWindowAndReloadOpener () {
  if ($('#closeWindow').val() === '1') {
    closeWindowAndUpdateOpener()
  }
}

function selectRedirect (redirect) {
  redirect.addEventListener('change', function (event) {
    if (this.options[this.selectedIndex].value) {
      window.location = window.location.pathname +
        '?' + this.dataset.redirect +
        '=' + this.options[this.selectedIndex].value
    }
  })
}

function timeline () {
  $('[data-datepicker]').datepicker({
    language: 'de',
    format: 'dd.mm.yyyy',
    weekStart: 1
  })
}

export function setVertragspartner (id, name, businessCustomerId) {
  $('#vertragspartnerID').attr('value', id)
  let content = '<a class="pointer" href="/person/' +
      id + '">Person <b>' + id + '</b></a> ' + name
  if (businessCustomerId) {
    content += '<p><strong>GKNR:</strong> ' + businessCustomerId + '</p>'
  }
  $('#vertragspartner').html(content)
}

function setAgreement (project) {
  $('input[name="project[agreement][project]"]')
    .attr('value', project.id)

  const content = $.parseHTML(
    '<a target="_blank" class="pointer" href="/auftrag/' +
    project.id + '">Auftrag <b>' + project.active_order_number +
    '</b></a><a href="#" class="pointer delete">x</a>'
  )

  $(content).last().on('click', clearAgreement)
  $('#agreement').html(content)
}

function clearAgreement (e) {
  $('input[name="project[agreement][project]"]')
    .attr('value', '')
  $(e.target).parent().html('')
  return false
}

function addLinkedProject (project) {
  const projectsContainer = $('#linked-projects')
  const duplicatedProjects = projectsContainer.children()
    .filter(function (child) {
      return this.dataset.project === project.id
    })
  if (duplicatedProjects.length > 0) return

  const content = $.parseHTML('<div data-project="' + project.id +
    '"><input type="hidden" name="project[linked_projects][' +
    project.id + '][project]" value="' + project.id + '">' +
    '<a target="_blank" class="pointer" href="/auftrag/' +
    project.id + '">Auftrag <b>' + project.active_order_number +
    '</b></a><a href="#" class="pointer delete">x</a></div>')

  $(content).children('.delete').on('click', deleteLinkedProject)
  projectsContainer.append(content)
}

function deleteLinkedProject (e) {
  $(e.target).parent().remove()
  return false
}

window.addEventListener('message', function (e) {
  const message = e.data

  switch (message.type) {
    case 'agreement':
      setAgreement(message.data)
      break
    case 'linked-project':
      addLinkedProject(message.data)
      break
  }
}, false)

window.addEventListener('beforeunload', function () {
  if (window.opener && $('main.concept').length > 0) {
    window.opener.location.reload()
  }
})

function postProject (e) {
  if (!window.opener) {
    return
  }
  let data
  let type
  const target = $(e.target).closest('a')

  if (target[0].hasAttribute('data-post-agreement')) {
    type = 'agreement'
    data = JSON.parse(target[0].dataset.postAgreement)
  } else if (target[0].hasAttribute('data-post-linked-project')) {
    type = 'linked-project'
    data = JSON.parse(target[0].dataset.postLinkedProject)
  }

  window.opener.postMessage({
    type,
    data
  }, '*')
  window.close()
}

function jumpToMail () {
  if (window.location.search === null) return

  const mail = window.location.search.match(/mail=([0-9]+)/)
  if (mail === null || mail.length !== 2 || mail[1] === '1') return

  const target = document.getElementById('task-' + mail[1])
  if (target === null) return

  target.scrollIntoView({ behavior: 'smooth' })
}

function datepickerValidator () {
  const dateFrom = document.getElementById('datepicker-from').value
  const dateTo = document.getElementById('datepicker-to').value

  const disabled = (dateFrom !== '' && !isDateValid(dateFrom)) || (dateTo !== '' && !isDateValid(dateTo))
  $('.datepicker-submit').prop('disabled', disabled)
  const errorSpan = document.getElementById('datepicker-error')
  errorSpan.style.display = !disabled ? 'none' : 'block'
}

function initUploader () {
  return createVue(ProjectUploader, 'project-uploader', {}, {
    server: 'server',
    canDelete: 'canDelete'
  })
}

export function showDetails () {
  function toggleMaxHeight () {
    if (this.style.maxHeight === 'none') {
      this.style.maxHeight = '200px'
    } else if (this.style.maxHeight === '200px') {
      this.style.maxHeight = 'none'
    }
  }

  function showProjectDetails () {
    $(this).addClass('layout-hidden-more')
      .css({ display: 'none' })
      .parents('.more')
      .each(toggleMaxHeight)
      .find('.layout-hidden')
      .css({ display: 'inline-block' })
  }

  // Alles anezeigen und Mehr anzeigen
  $('.more-toggle').on('click', function () {
    showProjectDetails.call(this)
    return false
  })

  // Alle Kommentare ausklappen
  $('#show-all-project-details').on('click', function () {
    const element = $(this)

    if (element.hasClass('active')) {
      element.removeClass('active').addClass('no-active')
      $('.more-toggle').each(function () {
        $(this).removeClass('layout-hidden-more')
          .css({ display: 'block' })
          .parents('.more')
          .each(toggleMaxHeight)
          .find('.layout-hidden')
          .css({ display: 'none' })
      })
    } else {
      element.removeClass('no-active').addClass('active')
      $('.more-toggle').each(showProjectDetails)
    }

    return false
  })
}

export function tvSingleSupplyValidator () {
  const tv = $('select[class="tv"]').val()
  const disabled = (tv === '0')

  $('.premium-valid').prop('disabled', disabled)
  if (disabled) {
    $('select[class="premium"]').prop('selectedIndex', null)
  }
}

export function projectInit () {
  if (window.updateContents === undefined) {
    window.updateContents = function () {
      window.location.reload()
    }
  }

  /**
   * DOM events
   */
  // Popup
  $('[data-close-window]').on('click', closeWindow)
  $('[data-new-window]').on('click', registerNewWindow)
  $('[data-post-agreement]').on('click', postProject)
  $('[data-post-linked-project]').on('click', postProject)
  $('[data-disabled]').on('click', e => e.preventDefault())
  $('#linked-projects .delete').on('click', deleteLinkedProject)
  $('#agreement .delete').on('click', clearAgreement)
  $('.field-submit').change(function () {
    $('form').submit()
  })
  $('#switch-all').click(switchAll)
  $('#switch-rt,#switch-infrastruktur,#switch-vertrieb').click(switchTasks)
  $('#datepicker-from, #datepicker-to').bind('keyup change paste', datepickerValidator)
  $('select[class="tv"]').on('change', tvSingleSupplyValidator)

  bindProjectEventListeners()
  timeline()
  jumpToMail()
  showDetails()

  const redirects = document.querySelectorAll('select[data-redirect]')
  let i
  for (i = 0; i < redirects.length; ++i) {
    selectRedirect(redirects[i])
  }

  // Vue.js widgets
  initConceptFormWidget()
  initSearchApp()
  initCountdownWindow()
  initCloseWindowAndReloadOpener()
  initTopicRights()
  initFlashMessages()
  initShowPrivilegesApp()
  initUserPrivilegeAccessRightsApp()
  initTopicTreeSelect()
  initUploader()
  initProjectFilter()
  tvSingleSupplyValidator()
}
