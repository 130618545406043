<template>
  <div>
    <div>
      <h2>Wohnungen:</h2>
      <a
        v-if="hasAddUnitPrivilege"
        class="ui primary labeled icon button"
        @click="addUnit"
      >
        <i class="icon plus" />
        WE hinzufügen
      </a>
      <div
        v-if="error"
        class="callout error"
      >
        {{ error }}
      </div>
    </div>
    <br>
    <div class="vl-parent">
      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        :opacity="0.7"
        color="#0a0a90"
      />
      <splitpanes
        class="default-theme"
        style="height: 800px"
      >
        <pane :size="25">
          <div class="people-search">
            <input
              v-model="peopleSearchQuery"
              type="text"
              placeholder="Bewohner durchsuchen..."
              title="Es kann nach Vorname, Nachname, Firma und Wohnung gesucht werden."
              name="people-search-query"
            >
            <button
              title="Suchfeld leeren"
              name="clear-people-search-query"
              @click="clearPeopleSearchQuery"
            >
              <i class="icon times" />
            </button>
          </div>
          <div class="scroll-container scroll-box">
            <div>
              <PeopleListComponent :people="filteredPeople" />
            </div>
          </div>
        </pane>
        <pane :size="75">
          <div class="units-search">
            <input
              v-model="unitsSearchQuery"
              type="text"
              placeholder="Wohnungen durchsuchen..."
              title="Es kann nach Lage im Gebäude sowie Vorname, Nachname, und Firma der Bewohner gesucht werden."
              name="units-search-query"
            >
            <button
              title="Suchfeld leeren"
              name="clear-units-search-query"
              @click="clearUnitsSearchQuery"
            >
              <i class="icon times" />
            </button>
            <div class="unit-occupied-filter">
              <label
                v-for="filter in availableOccupiedFilters"
                :key="filter.key"
              >
                <input
                  v-model="unitOccupiedFilter"
                  type="radio"
                  :value="filter.key"
                  name="unit-occupied-filter"
                >
                {{ filter.displayText }}
              </label>
            </div>
          </div>

          <div class="scroll-container scroll-box">
            <div class="units-container">
              <div
                v-for="unit in filteredUnits"
                :key="unit.id"
              >
                <UnitComponent
                  :unit="unit"
                  :has-delete-unit-privilege="hasDeleteUnitPrivilege"
                  @result="onResult"
                />
              </div>
            </div>
          </div>
        </pane>
      </splitpanes>
    </div>
  </div>
</template>

<script>
import PeopleListComponent from './PeopleListComponent.vue'
import UnitComponent from './UnitComponent.vue'
import { useBuildingStore } from '@/stores/BuildingStore'
import { mapState } from 'pinia'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/css/index.css'

export default {
  components: {
    PeopleListComponent,
    UnitComponent,
    Splitpanes,
    Pane,
    Loading
  },
  props: {
    buildingId: { type: Number, default: null },
    hasAddUnitPrivilege: {
      type: Boolean,
      required: false,
      default: () => false
    },
    hasDeleteUnitPrivilege: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  setup (props) {
    const buildingStore = useBuildingStore()
    buildingStore.fill(props.buildingId)

    return {
      buildingStore
    }
  },
  data () {
    return {
      error: null,
      peopleSearchQuery: '',
      unitsSearchQuery: '',
      unitOccupiedFilter: 'allUnits',
      isLoading: true,
      availableOccupiedFilters: [
        { key: 'allUnits', displayText: 'Alle' },
        { key: 'emptyUnits', displayText: 'Leere' },
        { key: 'occupiedUnits', displayText: 'Belegte' }
      ]
    }
  },
  computed: {
    ...mapState(useBuildingStore, {
      buildingData: 'buildingData'
    }),
    filteredPeople () {
      if (!this.peopleSearchQuery) {
        return this.buildingData.people
      }

      const searchTerms = this.peopleSearchQuery.toLowerCase().split(' ').filter(term => term)

      return this.buildingData.people.filter(person => {
        const nachname = person.nachname ? person.nachname.toLowerCase() : ''
        const vorname = person.vorname ? person.vorname.toLowerCase() : ''
        const firma = person.firma ? person.firma.toLowerCase() : ''
        const wohnung = person.wohnung ? person.wohnung.toLowerCase() : ''

        return searchTerms.every(term => nachname.includes(term) ||
            vorname.includes(term) ||
            firma.includes(term) ||
            wohnung.includes(term)
        )
      })
    },
    filteredUnits () {
      const searchTerms = this.unitsSearchQuery.toLowerCase().split(' ').filter(term => term)

      return this.buildingData.units.filter(unit => {
        const unitIsOccupied = unit.people && unit.people.length > 0
        if ((this.unitOccupiedFilter === 'occupiedUnits' && !unitIsOccupied) ||
            (this.unitOccupiedFilter === 'emptyUnits' && unitIsOccupied)
        ) {
          return false
        }

        if (!searchTerms || searchTerms.length === 0) {
          return true
        }

        const locationInBuilding = unit.location_in_building ? unit.location_in_building.toLowerCase() : ''
        const people = unit.people && unit.people.length > 0
          ? unit.people.map(person => {
            const nachname = person.nachname ? person.nachname.toLowerCase() : ''
            const vorname = person.vorname ? person.vorname.toLowerCase() : ''
            const firma = person.firma ? person.firma.toLowerCase() : ''

            return vorname + nachname + firma
          }).join(' ')
          : ''

        return searchTerms.every(term => locationInBuilding.includes(term) ||
            people.includes(term)
        )
      })
    }
  },
  watch: {
    buildingData (newVal) {
      if (newVal) {
        this.isLoading = false
      }
    }
  },
  methods: {
    onResult (message) {
      if (message.error) {
        this.error = message.error
      } else {
        this.error = null
      }
      this.buildingStore.fill(this.buildingId)
    },
    async addUnit () {
      await this.buildingStore.addUnit(this.buildingId)
    },
    clearPeopleSearchQuery () {
      this.peopleSearchQuery = ''
    },
    clearUnitsSearchQuery () {
      this.unitsSearchQuery = ''
    }
  }
}
</script>

<style scoped>
.scroll-container {
  flex: 1 1 auto;
  flex-wrap: wrap;
  height: calc(100% - 50px);
  overflow-y: auto;
  padding: 5px;
  margin: 10px;
}

.splitpanes.default-theme .splitpanes__pane {
  background-color: #fff;
}

.units-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  gap: 10px;
}

.people-search {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-top: 5px;
  margin-right: 30px;
}

.units-search {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-top: 5px;
  margin-right: 30px;
}

.units-search > input[type="text"] {
  width: auto;
  min-width: 50%;
}

.units-search label {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.units-search input[type="radio"] {
  display: inline;
  width: auto;
  min-width: 20px;
  transform: scale(1.5);
  margin-right: 7px;
}

.units-search input[type="radio"]:hover,
.units-search input[type="radio"]:focus {
  box-shadow: none;
}

.unit-occupied-filter {
  display: flex;
  align-items: center;
  margin-left: 30px;
}
</style>
