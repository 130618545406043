<template>
  <span class="EntityLink">
    <component
      :is="as"
      v-bind="{...attr, ...{class: 'pointer ' + ('class' in attr ? attr.class : '')}}"
    >
      <FomanticIcon
        v-if="'name' in icon || background"
        :style="{ backgroundColor: background }"
        v-bind="{...icon, ...{type: 'circular border'}}"
      />
      <b class="EntityLink-label">{{ label }}</b>
      <span class="EntityLink-reference">{{ reference }}</span>
    </component>
    <span class="EntityLink-info">{{ info }}</span>
  </span>
</template>
<script>
import FomanticIcon from '@/components/_partials/Atom/FomanticIcon.ce.vue'

export default {
  tag: 'entity-link',
  components: { FomanticIcon },
  props: {
    as: {
      type: String,
      default: 'a'
    },
    className: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    reference: {
      type: String,
      default: ''
    },
    info: {
      type: String,
      default: ''
    },
    icon: {
      type: Object,
      default: () => {
        return {}
      }
    },
    attr: {
      type: Object,
      default: () => {
        return {}
      }
    },
    background: {
      type: String,
      default: () => null
    }
  }
}
</script>
