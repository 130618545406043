export function vector (p1, p2) {
  return { x: p2.x - p1.x, y: p2.y - p1.y }
}

export function angleBetween (v1, v2) {
  const dotProduct = v1.x * v2.x + v1.y * v2.y
  const mag1 = Math.sqrt(v1.x * v1.x + v1.y * v1.y)
  const mag2 = Math.sqrt(v2.x * v2.x + v2.y * v2.y)
  const result = Math.acos(dotProduct / (mag1 * mag2))

  if (isNaN(result)) {
    throw new Error('invalid result')
  }

  return result
}

export function center (points) {
  if (points.length < 1) {
    throw new Error('At least one point is required')
  }

  let sumX = 0
  let sumY = 0

  points.forEach(point => {
    sumX += point.x
    sumY += point.y
  })

  const centerX = sumX / points.length
  const centerY = sumY / points.length

  return [centerX, centerY]
}

export function shoelaceArea (vertices) {
  let area = 0
  const n = vertices.length

  for (let i = 0; i < n; i++) {
    const { x: x1, y: y1 } = vertices[i]
    const { x: x2, y: y2 } = vertices[(i + 1) % n]
    area += x1 * y2 - y1 * x2
  }

  const result = Math.abs(area) / 2

  if (isNaN(result)) {
    throw new Error('invalid result')
  }

  return result
}

export function sharpness (canvas) {
  const context = canvas.getContext('2d')
  const width = canvas.width
  const height = canvas.height

  // Get image data from the canvas
  const imageData = context.getImageData(0, 0, width, height)
  const pixels = imageData.data

  // Convert the image to grayscale
  const grayscale = []
  for (let i = 0; i < pixels.length; i += 4) {
    const gray = 0.299 * pixels[i] + 0.587 * pixels[i + 1] + 0.114 * pixels[i + 2]
    grayscale.push(gray)
  }

  // Apply a Laplacian filter to detect edges
  const laplacian = []
  for (let y = 1; y < height - 1; y++) {
    for (let x = 1; x < width - 1; x++) {
      const center = grayscale[y * width + x]
      const neighbors = [
        grayscale[(y - 1) * width + x], // top
        grayscale[(y + 1) * width + x], // bottom
        grayscale[y * width + (x - 1)], // left
        grayscale[y * width + (x + 1)] // right
      ]
      const laplacianValue = 4 * center - neighbors.reduce((a, b) => a + b, 0)
      laplacian.push(laplacianValue)
    }
  }

  // Calculate variance of Laplacian values
  const mean = laplacian.reduce((a, b) => a + b, 0) / laplacian.length
  const result = laplacian.reduce((sum, value) => sum + (value - mean) ** 2, 0) / laplacian.length

  if (isNaN(result)) {
    throw new Error('invalid result')
  }

  return result
}

export function rectangularity (points) {
  if (points.length !== 4) {
    throw new Error('Exactly 4 points are required.')
  }

  // Calculate vectors between consecutive points (and close the shape loop)
  const vectors = [
    vector(points[0], points[1]),
    vector(points[1], points[2]),
    vector(points[2], points[3]),
    vector(points[3], points[0])
  ]

  // Calculate angles between consecutive vectors
  const angles = [
    angleBetween(vectors[0], vectors[1]),
    angleBetween(vectors[1], vectors[2]),
    angleBetween(vectors[2], vectors[3]),
    angleBetween(vectors[3], vectors[0])
  ]

  // Calculate a score based on closeness to 90 degrees
  const targetAngle = Math.PI / 2 // 90 degrees in radians
  const maxDeviation = targetAngle // max deviation for scoring
  let score = 0

  for (const angle of angles) {
    const deviation = Math.abs(angle - targetAngle)
    const normalizedScore = 1 - Math.min(deviation / maxDeviation, 1)
    score += normalizedScore
  }

  // Average the score to get a value between 0 and 1

  const result = score / angles.length

  if (isNaN(result)) {
    throw new Error('invalid result')
  }

  return result
}

export function centeredness (imageWidth, imageHeight, points) {
  const [x, y] = center(points)

  // Mitte des Bildes berechnen
  const centerX = imageWidth / 2
  const centerY = imageHeight / 2

  // Euklidischer Abstand (https://de.wikipedia.org/wiki/Euklidischer_Abstand)
  const distanceToCenter = Math.sqrt(Math.pow(x - centerX, 2) + Math.pow(y - centerY, 2))

  // Maximale mögliche Distanz berechnen (Ecke zur Mitte)
  const maxDistance = Math.sqrt(Math.pow(centerX, 2) + Math.pow(centerY, 2))

  // Nähe zur Mitte in Prozent
  const result = (maxDistance - distanceToCenter) / maxDistance

  if (isNaN(result)) {
    throw new Error('invalid result')
  }

  return result
}

export function proximityTo (value, maxRange) {
  value = Math.abs(value) // Use the absolute value of `value`

  // If the value exceeds maxRange, subtract maxRange repeatedly until it is within range
  while (value > maxRange) {
    value -= maxRange
  }

  const midpoint = maxRange / 2
  const maxDistance = midpoint // Maximum distance from the midpoint
  const distance = Math.abs(midpoint - value)

  // Calculate proximity as a percentage
  const proximityPercentage = (maxDistance - distance) / maxDistance

  if (isNaN(proximityPercentage)) {
    throw new Error('invalid result')
  }

  return proximityPercentage
}

export function trapezoidBoundingBoxRotation (points) {
  if (points.length !== 4) {
    throw new Error('Four points are required to define a trapezoid.')
  }

  // Helper function to calculate the angle between two points
  function angleBetweenPoints (p1, p2) {
    return Math.atan2(p2.y - p1.y, p2.x - p1.x)
  }

  let minArea = Infinity
  let bestAngle = 0

  for (let i = 0; i < points.length; i++) {
    const angle = angleBetweenPoints(points[i], points[(i + 1) % points.length])

    // Rotate all points by -angle to align the edge horizontally
    const rotatedPoints = points.map(point => {
      const x = point.x * Math.cos(-angle) - point.y * Math.sin(-angle)
      const y = point.x * Math.sin(-angle) + point.y * Math.cos(-angle)
      return { x, y }
    })

    const minX = Math.min(...rotatedPoints.map(p => p.x))
    const maxX = Math.max(...rotatedPoints.map(p => p.x))
    const minY = Math.min(...rotatedPoints.map(p => p.y))
    const maxY = Math.max(...rotatedPoints.map(p => p.y))

    const width = maxX - minX
    const height = maxY - minY
    const area = width * height

    if (area < minArea) {
      minArea = area
      bestAngle = angle
    }
  }

  const rotationInDegrees = bestAngle * (180 / Math.PI)

  if (isNaN(rotationInDegrees)) {
    throw new Error('invalid result')
  }

  return rotationInDegrees
}
