<template>
  <div
    class="unit-box"
    :style="{backgroundColor: getBackgroundColor()}"
  >
    <div
      class="location-label"
      :style="{backgroundColor: getBackgroundColor(true)}"
    >
      <div
        ref="locationTextField"
        :contenteditable="editing"
        class="location-field"
        tabindex="0"
      />
      <template v-if="editing">
        <a
          href="#"
          @click.prevent="submitEditing"
        >
          <i class="icon check" />
        </a>
        <a
          href="#"
          @click.prevent="cancelEditing"
        >
          <i class="icon times" />
        </a>
      </template>
      <div v-else>
        <a
          v-if="hasDeleteUnitPrivilege"
          :class="{ 'disabled': !isUnitDeletable }"
          href="#"
          @click.prevent="removeUnit"
        >
          <i class="icon trash" />
        </a>
        <a
          href="#"
          @click.prevent="startEditing"
        >
          <i class="icon pen" />
        </a>
      </div>
    </div>
    <draggable
      :list="unit.people"
      group="people"
      item-key="id"
      class="unit"
      @change="movePersonToUnit"
      @dragstart="handleDragStart"
      @dragend="handleDragEnd"
    >
      <template #item="{element: person}">
        <Person
          :key="person.id"
          :person="person"
        />
      </template>
    </draggable>
    <div class="home-id">
      <div>
        <a
          ref="tooltipActivityTrigger"
          href="#"
          :class="connectivitySymbol"
        >
          <i class="icon ethernet" />
        </a>
        <a
          ref="tooltipOntTrigger"
          href="#"
          :class="ontSymbol"
        >
          <i class="icon exchange alternate" />
        </a>
      </div>
      <UnitLink :unit="unit" />
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import Person from './PersonPreviewComponent.vue'
import { useBuildingStore } from '@/stores/BuildingStore'
import {
  handleDragStart,
  handleDragEnd,
  updatePerson,
  updateUnit,
  deleteUnit,
  createConnectivityContent,
  createOntContent
} from '@/js/utils/unitAssignmentUtil'
import tippy from 'tippy.js'
import UnitLink from '@/components/_partials/UnitLink.vue'

export default {
  components: {
    UnitLink,
    draggable,
    Person
  },
  props: {
    unit: {
      type: Object,
      default: () => ({})
    },
    hasDeleteUnitPrivilege: {
      type: Boolean,
      required: true
    }
  },
  setup () {
    const buildingStore = useBuildingStore()

    return {
      buildingStore
    }
  },
  data () {
    return {
      editing: false,
      actualLocation: this.unit.location_in_building,
      activityTippyInstance: null,
      ontTippyInstance: null
    }
  },
  computed: {
    isUnitDeletable () {
      const { ont, people, connectivities } = this.unit
      const isOntEmpty = Object.keys(ont).length === 0

      return this.buildingStore.units.length !== 1 &&
          isOntEmpty && people.length === 0 &&
          (connectivities && connectivities.length === 0)
    },
    ontSymbol () {
      return (!this.unit.ont || this.unit.ont.mac === undefined) ? { disabled: true } : { enabled: true }
    },
    connectivitySymbol () {
      const connectivities = this.unit.connectivities
      return (connectivities && connectivities.length > 0) ? { enabled: true } : { disabled: true }
    }
  },
  mounted () {
    this.setLocationDescription()
    const self = this
    this.activityTippyInstance = tippy(this.$refs.tooltipActivityTrigger, {
      onShow (instance) {
        if (self.unit.connectivities.length > 0) {
          instance.setContent(createConnectivityContent(self.unit.connectivities))
        }
      }
    })
    this.ontTippyInstance = tippy(this.$refs.tooltipOntTrigger, {
      onShow (instance) {
        if (self.unit.ont.mac !== undefined) {
          instance.setContent(createOntContent(self.unit.ont))
        }
      }
    })
  },
  beforeUnmount () {
    if (this.activityTippyInstance) {
      this.activityTippyInstance.unmount()
    }
    if (this.ontTippyInstance) {
      this.ontTippyInstance.unmount()
    }
  },
  methods: {
    handleDragStart,
    handleDragEnd,
    updatePerson,
    updateUnit,
    deleteUnit,
    getBackgroundColor (isEditingArea = false) {
      if (!this.editing && isEditingArea) {
        return null
      }
      if ((this.unit.people && this.unit.people.length > 0) || (this.unit.ont && this.unit.ont.mac)) {
        return isEditingArea ? 'rgb(199,147,114)' : 'rgb(219,172,144)'
      }
      return isEditingArea ? 'rgb(153,151,145)' : 'rgb(192,189,182)'
    },
    async movePersonToUnit (event) {
      if (!event.added) {
        return
      }
      const { building_id: buildingId, ...person } = {
        ...event.added.element,
        unit_id: this.unit.id
      }
      try {
        await this.updatePerson(person, this.buildingStore)
        this.$emit('result', { person: event.added.element })

        try {
          if (!this.unit.location_in_building && person.wohnung && buildingId) {
            await this.updateUnit({
              id: this.unit.id,
              location_in_building: person.wohnung
            }, this.buildingStore)
            this.actualLocation = person.wohnung
            this.setLocationDescription()
          }
        } catch (error) {
          // Just don't set the location if not possible.
        }
      } catch (error) {
        this.$emit('result', { ...error.response.data, person: event.added.element })
      }
    },
    onKeyDown (event) {
      if (event.altKey && event.key === 'Enter') {
        const selection = window.getSelection()
        const range = selection.getRangeAt(0)
        const brNode = document.createElement('br')

        range.deleteContents()
        range.insertNode(brNode)
        range.setStartAfter(brNode)
        range.setEndAfter(brNode)
        range.collapse(false)

        selection.removeAllRanges()
        selection.addRange(range)
      } else if (event.key === 'Enter') {
        this.submitEditing()
      } else if (event.key === 'Escape') { // Escape isn't triggered on keypress, therefore checking keydown.
        this.cancelEditing()
      }
    },
    completeEditing () {
      this.editing = false
      this.$refs.locationTextField.removeEventListener('keydown', this.onKeyDown)
    },
    startEditing () {
      this.editing = true
      this.setLocationDescription()

      this.$refs.locationTextField.focus()
      if (this.$refs.locationTextField.childNodes.length > 0) {
        const range = document.createRange()
        const selection = window.getSelection()

        range.selectNodeContents(this.$refs.locationTextField)
        range.setEnd(this.$refs.locationTextField, this.$refs.locationTextField.childNodes.length - 1)
        selection.removeAllRanges()
        selection.addRange(range)
      }
      this.$refs.locationTextField.addEventListener('keydown', this.onKeyDown)
    },
    cancelEditing () {
      this.completeEditing()
      this.setLocationDescription()
    },
    async submitEditing () {
      this.completeEditing()

      let locationInBuilding = this.$refs.locationTextField.innerText.trim()

      if (locationInBuilding === '') {
        locationInBuilding = null
      } else {
        locationInBuilding = locationInBuilding.replace(/\u00a0/g, ' ') // Translate non-breaking spaces.
      }
      try {
        await this.updateUnit({
          id: this.unit.id,
          location_in_building: locationInBuilding
        }, this.buildingStore)

        this.actualLocation = locationInBuilding
        this.$emit('result', {})
      } catch (error) {
        this.$emit('result', { ...error.response.data })
      }
      this.setLocationDescription()
    },
    setLocationDescription () {
      if (this.actualLocation) {
        if (this.actualLocation.length > 25 && !this.editing) {
          this.$refs.locationTextField.innerText = this.actualLocation.substring(0, 25) + '…'
        } else {
          this.$refs.locationTextField.innerText = this.actualLocation
        }
      } else if (!this.editing) {
        this.$refs.locationTextField.innerText = 'Keine Lageinformation'
      } else {
        this.$refs.locationTextField.innerText = ''
      }
      // Since browsers don't show trailing line breaks, we always need an additional line break at the end.
      this.$refs.locationTextField.appendChild(document.createElement('br'))
    },
    async removeUnit () {
      try {
        await this.deleteUnit(this.unit, this.buildingStore)
      } catch (error) {
        this.$emit('result', { ...error.response.data })
      }
    }
  }
}
</script>

<style scoped>
.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.unit {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 20px 20px 40px;
  box-sizing: border-box;
  flex-flow: column wrap;
  overflow-y: auto;
  margin-top: 25px;
}

.unit-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 310px;
  height: 170px;
  margin-top: 20px;
  background-color: #f0f0f0;
  position: relative;
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(0 0 0 / 10%);
}

.location-label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 10px 5px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.location-label .location-field {
  flex-grow: 1;
}

.home-id {
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  padding: 0 0.5rem;
  width: 100%;
  color: #444;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.enabled {
  color: #080878
}

.home-id > div {
  flex: 1;
}
</style>
