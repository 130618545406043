import MaterialIcon from '@/components/_partials/Atom/FomanticIcon.ce.vue'
import EntityLink from '@/components/_partials/Molecule/EntityLink.ce.vue'
import DetailTable from '@/components/_partials/Molecule/AccordionTable.ce.vue'
import TaskHistory from '@/components/_partials/Organism/TaskHistory.ce.vue'
import ServiceTable from '@/components/_partials/Organism/ServiceTable.ce.vue'
import WindowButton from '@/components/_partials/WindowButton.vue'
import AvatarEditor from '@/components/_partials/Organism/AvatarEditor.ce.vue'
import OpenAccessIcon from '@/components/_partials/Atom/OpenAccessIcon.ce.vue'
import EntityCardList from '@/components/_partials/Organism/EntityCardList.ce.vue'
import EntityCard from '@/components/_partials/Molecule/EntityCard.ce.vue'
import UnitList from '@/components/_partials/Templates/UnitList.ce.vue'
import DocumentList from '@/components/_partials/Organism/DocumentList.ce.vue'
import DocumentArea from '@/components/Document/DocumentArea.ce.vue'
import UnitSelection from '@/components/Building/UnitSelection.vue'
import UnitAssignmentApp from '@/components/Building/UnitAssignmentApp.vue'
import QRSnapshoter from '@/components/_partials/Molecule/QRSnapshoter.ce.vue'
import PoiMap from '@/components/_partials/PoiMap.vue'

export const StateAware = [
  UnitAssignmentApp
]

export default {
  MaterialIcon,
  DocumentArea,
  DocumentList,
  UnitSelection,
  QRSnapshoter,
  UnitAssignmentApp,
  EntityLink,
  WindowButton,
  AvatarEditor,
  OpenAccessIcon,
  DetailTable,
  TaskHistory,
  ServiceTable,
  EntityCardList,
  EntityCard,
  UnitList,
  PoiMap
}
