<template>
  <div>
    <label>Filter</label>
    <treeselect
      v-model="selectedFilters"
      placeholder="Alle Projekte anzeigen"
      clear-all-text="Auswahl zurücksetzen"
      :multiple="true"
      :disable-branch-nodes="true"
      :searchable="false"
      :flat="false"
      :options="options"
      :before-clear-all="closeMenu"
      @close="closeMenu"
    >
      <template #value-label="{ node }">
        {{ node.raw.parent }}: {{ node.label }}
      </template>
    </treeselect>
  </div>
</template>

<script>
import Treeselect from '@m-kusumgar/vue3-treeselect'
import * as R from 'ramda'

const FILTERS = ['projectType', 'status']

export function fetchSelectedFilters (searchString) {
  const entries = Array.from(new URLSearchParams(searchString).entries())

  return R.chain(function ([name, value]) {
    const values = R.isEmpty(value) ? [] : R.split(',', value)

    return FILTERS.includes(name)
      ? R.map(R.concat(`${name}_`), values)
      : []
  }, entries)
}

export function addFilters (selectedValues, searchString) {
  const params = new URLSearchParams(searchString)

  FILTERS.forEach(function (paramName) {
    params.delete(paramName)
  })
  // Take a list of pairs [['projectType', 0], ['projectType', 2]], take only
  // the second elements of the pairs and join them with ','
  const join = R.compose(R.join(','), R.map(R.last))

  const combineParameters = R.compose(
    R.toPairs,
    R.map(join),
    R.groupBy(R.head),
    R.map(R.split('_'))
  )

  combineParameters(selectedValues).forEach(function ([name, value]) {
    params.set(name, value)
  })
  params.set('p', '1')
  return new URLSearchParams(params)
}

export default {
  components: {
    treeselect: Treeselect
  },
  props: {
    phases: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      options: [
        {
          label: 'Typ',
          id: 'type',
          children: [
            {
              id: 'projectType_0',
              label: 'Geschäftskunde',
              parent: 'Typ'
            },
            {
              id: 'projectType_2',
              label: 'Backbone',
              parent: 'Typ'
            },
            {
              id: 'projectType_6',
              label: 'Wohnungswirtschaft',
              parent: 'Typ'
            }
          ]
        },
        {
          label: 'Phase',
          id: 'status',
          children: this.phases.map(phase => ({ ...phase, parent: 'Phase' }))
        }
      ],
      selectedFilters: []
    }
  },
  created () {
    this.selectedFilters = fetchSelectedFilters(window.location.search)
  },
  methods: {
    closeMenu (selectedValues = []) {
      window.location.search = addFilters(selectedValues, window.location.search)
    }
  }
}
</script>
